import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Counter } from './pages/counter/Counter';
import { store } from './store/index'
import { Provider } from 'react-redux'
import { BrowserRouter as Router,Routes,Route,Navigate,useNavigate } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Dashboard } from './pages/dashboard/Dashboard';
import { Header } from './components/header/Header';
import { Sidebar } from './components/sidebar/Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Login } from './components/login/Login';
import './assets/scss/new-ui.scss';
import { MainPage } from './pages/mainPage/MainPage';
import { getCookie } from './utils/cookies';
import { fetchUserData } from './services/user';
import { useAppSelector, useAppDispatch } from './store/hook'
import { addUserData } from './store/user/userSlice';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MainDashboard } from './pages/mainDashboard/MainDashboard';
import { General } from './pages/goals/General';
import { Review } from './pages/review/Review';
import { Overview } from './components/dashboard/Overview';
import { Configure } from './components/dashboard/Configure';
import { Goals } from './pages/goals/Goals';
import { Production } from './pages/goals/Production';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardCharts } from './pages/dashboardCharts/DashboardCharts';

  toast.configure({ closeButton: false, closeOnClick: true, className: 'toaster', autoClose: 1500 });

const AppMain = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const login: any = getCookie();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router>
        <Routes>
          <Route path='/login' element={<Login />}/>
          <Route path='/' element={<MainPage />}>
            <Route path='/maindashboard' element={<MainDashboard />} />
            <Route path='/dashboard' element={<Overview />} >
              {/* <Route path='overview' element={<Overview />} />
              <Route path='configure' element={<Configure />} /> */}
            </Route>
            <Route path='/statistics' element={<DashboardCharts />} />
            <Route path="/configure" element={<Configure />} />
            <Route path='/goals' element={<Goals />} >
              <Route path='general' element={<General />} />
              <Route path='production' element={<Production />} />
              </Route>
            {/* <Route path='/review' element={<Review />} /> */}
          </Route>
          <Route path='counter' element={<Counter />} />
        </Routes>
      </Router>
      </MuiPickersUtilsProvider>
  )
}
const Routing = (
  <Provider store={store}>
        <AppMain></AppMain>
    </Provider >
);

ReactDOM.render(
  Routing,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();