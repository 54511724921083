import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import configs from "../configs/apiConfigs";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const addGoalSetting = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'goaladd/',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const getGoalSetting = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'goalsettings/',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};