import React, { useEffect,useState } from 'react';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';

export const DateModal = ({ show,maxDate='', handleClose, handleDateChange }: any) => {
    const [date, setDate]: any = useState(null);

    const handleChangeDate = (date: any) => {
        setDate(date);
    }

    const handleSave = () => {
        let sDate: any, eDate: any;
        if (date) {
            sDate = moment(date).format('YYYY-MM-DD');
            eDate = moment(date).format('YYYY-MM-DD');
        } 
        setDate(null);
        handleDateChange(sDate, eDate);
    }
  return (
     <Modal
            show={show}
            onHide={handleClose}
            className="newcommon close-chat"
            centered
        >
            <Modal.Header>
                <div className="modal-title">Date</div>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => { handleClose() }}
                ></button>
            </Modal.Header>

            <Modal.Body>
                <div className='d-flex flex-row m-2'>
                    <KeyboardDatePicker
                        className='m-2'
                        margin="none"
                        label="Date"
                        format="YYYY/MM/DD"
                        // minDate={minDate}
                        maxDate={maxDate}
                        value={date}
                        onChange={handleChangeDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                  disabled={!date}
                    className="btn btn-primary"
                    onClick={() => {
                        handleSave();
                    }}
                >
                    Save
                </button>
            </Modal.Footer>
        </Modal>
  )
}
