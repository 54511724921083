import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../../../assets/js/canvasjs.react';
import { fetchChartData } from '../../../services/dashboard';
import { useAppSelector } from '../../../store/hook';
import * as _ from 'lodash';
import WeekModal from '../common/WeekModal';
var CanvasJS:any = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
export const Scheduled = ({ clinicId }: any) => {

    const [scheduledData, setScheduledData]: any = useState('');
    const [dataPoints, setDataPoints] = useState([{ label: 'May 1', y: 1 }]);
    const userData = useAppSelector((state) => state.user.user);
    const [isShowNoData, setIsShowNoData] = useState(false);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(6, 'days').format('YYYY-MM-DD'));
    const [isDateChange, setIsDateChange] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let controller: any;
    useEffect(() => {
        if (clinicId) {
            controller = new AbortController();
            setTimeout(() => {
                fetchData();
            }, 1000);
        }
        return () => {
            if (controller) {
                controller.abort()
            }
        }
    }, [startDate, endDate, clinicId]);
    
    const fetchData = () => {
        setIsLoading(true);
        fetchChartData('datewiseschedule', clinicId, startDate, endDate,controller)
            .then((success) => {
                setIsLoading(false);
                if (success && success.scheduledAppointments.length > 0) {
                    setScheduledData({
                        confirmedAppointments: success.confirmedAppointments, scheduledProduction: success.scheduledProduction,
                        scheduledAppointments: success.scheduledAppointments.map((item: any) => {
                            return { label: moment(item.date, 'YYYY-MM-DD').format('MMM DD'), y: Number(item.count) }
                        }),
                        totalSchedulePatients: _.sumBy(success.scheduledAppointments, function (o: any) { return Number(o.count); }),
                        totalConfirmedPatients: _.sumBy(success.confirmedAppointments, function (o: any) { return Number(o.count); })
                    })
                } else {
                    setIsShowNoData(true);
                }
                
            })
            .catch((error) => {
                setIsLoading(false);
                console.log('error while fetching data - schedule', error);
            
            })
    }
    useEffect(() => {
        if (scheduledData && scheduledData.scheduledAppointments.length > 0) {
            let options = {
                animationEnabled: true,
                theme: "light2",
                // title: {
                //     text: "Scheduled"
                // },
                axisY: {
                    title: "Appointments",
                    // interval: 1,
                },
                axisX: {
                    interval: 1
                },
                data: [{
                    type: "line",
                    markerSize: '8',
                    dataPoints: scheduledData.scheduledAppointments,
                }]
            }
            var charts = new CanvasJS.Chart("scheduled", options);
            charts.render();
        }
    }, [scheduledData]);

    const handleDateChange = (sDate: any, eDate: any) => {
        setStartDate(sDate);
        setEndDate(eDate);
        setIsDateChange(false)
    }
    return (
        <div className="col-4 mb-4">
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h6 className="m-0">Scheduled</h6>
                    <div>
                        <button type="button" onClick={() => { setIsDateChange(true) }} className="btn p-1"><i className="bi bi-calendar-week"></i></button>
                        {/* <button type="button" className="btn p-1"><i className="bi bi-info-circle"></i></button> */}
                    </div>
                </div>
                <div className="card-body" style={{height:'290px'}}>
                    {
                        (isLoading) ?
                            <div className="mt-5 loder_in rounded text-center">
                                <div className="spinner-border align-items-center" role="status">
                                </div>
                                <div className="mt-2">Loading...</div>
                            </div>
                            :
                            <>
                                <div className="graph-area">
                                    {/* <img src={require("../../../assets/images/chart/Scheduled.png")} alt='image not found' /> */}
                                    {
                                        isShowNoData &&
                                        <div>No Record found.</div>
                                    }
                                    <div className='w-100 h-100' id="scheduled"></div>
                                </div>
                                <div className="bottom-data d-flex gap-3">
                                    <div className="btms-data">
                                        <span>Scheduled Patients</span>
                                        <div>{scheduledData && scheduledData.totalSchedulePatients}</div>
                                    </div>
                                    <div className="btms-data">
                                        <span>Scheduled Production</span>
                                        <div>{scheduledData && Math.round(scheduledData.scheduledProduction) ? "$ " + Math.round(scheduledData.scheduledProduction) : '$0'}</div>
                                    </div>
                                    <div className="btms-data">
                                        <span>Confirmed Patients</span>
                                        <div>{Math.round(scheduledData.totalConfirmedPatients)}</div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
            {
                (isDateChange) &&
                <WeekModal
                    show={isDateChange}
                    minDate={moment().startOf('week').format('YYYY/MM/DD')}
                    handleClose={() => { setIsDateChange(false) }}
                    handleDateChange={(sDate: any, eDate: any) => { handleDateChange(sDate, eDate) }}
                />
            }
        </div>
    )
}
