import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/index'

// Define a type for the slice state
interface DashboardState {
  dashboardData: any,
  dashboardChartData: any;
}

// Define the initial state using that type
const initialState: DashboardState = {
  dashboardData: {},
  dashboardChartData:{},
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addDashboardData: (state,action: PayloadAction<number>) => {
      state.dashboardData = action.payload
    },
    addDashboardChartData: (state,action: PayloadAction<number>) => {
      state.dashboardChartData = action.payload
    },
  },
})

export const { addDashboardData,addDashboardChartData } = dashboardSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.dashboard

export default dashboardSlice.reducer