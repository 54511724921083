const CONSTANT_LABELS = {
    LABELS: {
        LOGIN: "Signin",
        EMAIL: "Email address:",
        PASSWORD: "Password:",
        FORGOT_PASSWORD: "Forget Password?",
    },
    BUTTON_LABEL: {
        LOGIN: "Sign in",
        SEND_LINK: "Reset password",
        CANCLE: "Cancel",
        SAVE: "Save"
    },
    LINK: {
        FORGOT_PASSWORD: "Forgot Password ?",
        LEGAL: "Legal",
        COPYRIGHT: "Copyright © " + new Date().getFullYear() + " XCARE PRO. All rights reserved.",
        PRIVACY: "Privacy",
        RETURN_TO_SIGNIN: "Back to signin",
        PROFILE: "Profile",
        PROVIDER_AVAILABILITY: "Providers Availablity",
        APPOINTMENT: "Appointments",
        SETTINGS: "Settings"
    },
}

export default CONSTANT_LABELS;