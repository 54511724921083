import React, { useEffect,useState } from 'react';
import { Alert, Col, Form, Button, Modal, OverlayTrigger, Tooltip, } from "react-bootstrap";
import moment from 'moment';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { toast } from 'react-toastify';

export const CustomDateModal = ({ show, handleClose, handleChangeStartEndDate }: any) => {
    const [startDate, setStartDate]: any = useState(null);
    const [endDate, setEndDate]: any = useState(null);

    const handleChangeStartDate = (date: any) => {
        setStartDate(date);
    }
    const handleChangeEndDate = (date: any) => {
        setEndDate(date);
    }
    const handleSave = () => {
        let sDate: any = moment(startDate).format('YYYY-MM-DD');
        let eDate: any = moment(endDate).format('YYYY-MM-DD');
        let diff:any = moment(eDate).diff(moment(sDate).format('YYYY-MM-DD'), 'months');
        if (diff > 6) {
            toast.error('End date should not be more than 6 months from start date.');
            return;
        }
        handleChangeStartEndDate(sDate, eDate);
    }
    return (
        <Modal
            show={show}
            onHide={handleClose}
            className="newcommon close-chat"
            centered
        >
            <Modal.Header>
                <div className="modal-title">Custom Date</div>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => { handleClose() }}
                ></button>
            </Modal.Header>

            <Modal.Body>
                <div className='d-flex flex-row m-2'>
                    <KeyboardDatePicker
                        className='mx-2'
                        margin="none"
                        id="date-picker-dialog"
                        label="Start Date"
                        format="YYYY/MM/DD"
                        minDate={moment().subtract(6,'months')}
                        //   minDate={new Date()}
                        value={startDate}
                        onChange={handleChangeStartDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    <KeyboardDatePicker
                        margin="none"
                        id="date-picker-dialog"
                        label="End Date"
                        format="YYYY/MM/DD"
                        minDate={moment().subtract(6,'months')}
                        value={endDate}
                        onChange={handleChangeEndDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    disabled={!startDate || !endDate}
                    className="btn btn-primary"
                    onClick={() => {
                        handleSave();
                    }}
                >
                    Save
                </button>
            </Modal.Footer>
        </Modal>
    );
};
