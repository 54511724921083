import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../../../assets/js/canvasjs.react';
import { fetchChartData } from '../../../services/dashboard';
import * as _ from 'lodash';
import WeekModal from '../common/WeekModal';
var CanvasJS:any = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const NewPatients = ({clinicId}:any) => {
    const [dataPoints, setDataPoints] = useState([{ label: 'May 1', y: 5 }, { label: 'May 2', y: 10 }, { label: 'May 3', y: 15 }, { label: 'May 4', y: 8 }, { label: 'May 5', y: 11 }, { label: 'May 6', y: 5 }, { label: 'May 7', y: 13 }]);
    const [newPatientData, setNewPatientData]:any = useState('');
    const [isShowNoData, setIsShowNoData] = useState(false);
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [startDate, setStartDate] = useState(moment().subtract(6,'days').format('YYYY-MM-DD'));
    const [isDateChange, setIsDateChange] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let controller: any;
    useEffect(() => {
        if (clinicId) {
            controller = new AbortController();
            setTimeout(() => {
                fetchData();
            }, 1000);
        }
        return () => {
            if (controller) {
                controller.abort()
            }
        }
    }, [startDate,endDate,clinicId]);
    
    const fetchData = () => {
        setIsLoading(true);
        fetchChartData('newleads',clinicId, startDate, endDate,controller)
            .then((success) => {
                setIsLoading(false);
                if (success && success.newLeads.length > 0) {
                    setNewPatientData({
                        ...success,
                        newLeads: success.newLeads.map((item: any) => {
                            return { label: moment(item.date, 'YYYY-MM-DD').format('MMM DD'), y: Number(item.count) }
                        }),
                        totalNewPatients: _.sumBy(success.newLeads, function(o:any) { return Number(o.count); }),
                    })
                } else {
                    setIsShowNoData(true);
                }
                
            })
            .catch((error) => {
                setIsLoading(false);
                console.log('error while fetching data - schedule', error);
            })
    }
    useEffect(() => {
        if (newPatientData && newPatientData.newLeads.length > 0) {
            let options = {
                animationEnabled: true,
                theme: "light2",
                // title: {
                //     text: "New Patients"
                // },
                axisY: {
                    title: "Appointments",
                },
                axisX: {
                    interval: 1
                },
                data: [{
                    type: "line",
                    markerSize: '8',
                    dataPoints: newPatientData.newLeads
                }]
            }
            var charts = new CanvasJS.Chart("newpatients", options);
            charts.render();
        }
    }, [newPatientData]);

    const handleDateChange = (sDate: any, eDate: any) => {
        setStartDate(sDate);
        setEndDate(eDate);
        setIsDateChange(false)
    }
    return (
        <div className="col-4 mb-4">
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h6 className="m-0">New Patients</h6>
                    <div>
                        <button type="button" className="btn p-1" onClick={()=>{setIsDateChange(true)}}><i className="bi bi-calendar-week"></i></button>
                        {/* <button type="button" className="btn p-1"><i className="bi bi-info-circle"></i></button> */}
                    </div>
                </div>
                <div className="card-body" style={{height:'290px'}}>
                    {
                        (isLoading) ?
                            <div className="mt-5 loder_in rounded text-center">
                                <div className="spinner-border align-items-center" role="status">
                                </div>
                                <div className="mt-2">Loading...</div>
                            </div>
                            :
                            <>
                                <div className="graph-area">
                                    {/* <img src={req]uire("../../../assets/images/chart/New-Patients.png")} alt='image not found' /> */}
                                    {
                                        isShowNoData &&
                                        <div>No Record found.</div>
                                    }
                                    <div className='w-100 h-100' id="newpatients"></div>
                                </div>
                                <div className="bottom-data d-flex gap-3">
                                    <div className="btms-data">
                                        <span>New Patients</span>
                                        <div>{newPatientData && newPatientData.totalNewPatients}</div>
                                    </div>
                                    <div className="btms-data">
                                        <span>Completed Production</span>
                                        <div>{newPatientData && newPatientData.completedProduction ? "$ " + newPatientData.completedProduction : '$0'}</div>
                                    </div>
                                    <div className="btms-data">
                                        <span>New Patients Unscheduled</span>
                                        <div>{Math.round(newPatientData.newLeadsUnscheduled)}</div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
            {
                (isDateChange) &&
                <WeekModal
                    show={isDateChange}
                    handleClose={() => { setIsDateChange(false) }}
                    handleDateChange={(sDate: any, eDate: any) => { handleDateChange(sDate, eDate) }}
                />
            }
        </div>
    )
}
