const CONSTANT_TITLES = {
    TITLES: {
        LOGIN: "Signin - xcarepro",
        RESET_PASSWORD_LINK: "Reset Password Link",
        FORGOT_PASSWORD: "Forgot Passowrd - Clinic Panel - xcarepro",
        MY_ACCOUNT: "My Account",
        XCAREPRO: "xcarepro",
        CLINIC_CREATE: "New Clinic - xcarepro",
        DOCTOR_CREATE: "New Doctor - xcarepro",
        PROFILE: "Profile",
        SETTINGS: "Settings",
        PROVIDER_AVAILABILITY: "Provider Availability",
        APPOINTMENTS: "Appointments",
    }
}

export default CONSTANT_TITLES;