import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../../../assets/js/canvasjs.react';
import { fetchChartData, fetchUnscheduledData } from '../../../services/dashboard';
import * as _ from 'lodash';
import WeekModal from '../common/WeekModal';
import { loadingEnd, loadingStart } from '../../../store/loadingIndicator/loadingIndicatorSlice';
import { useAppDispatch } from '../../../store/hook';
import { DateModal } from '../common/DateModal';
var CanvasJS:any = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const UnScheduled = ({ clinicId }: any) => {
    const [dataPoints, setDataPoints] = useState([{ name: 'May 1', y: 5, color: '#FFCD46' }, { name: 'May 1', y: 20, color: '#4c8BF5' }, { name: 'May 1', y: 20, color: '#ed7d31' }, { name: 'May 1', y: 20, color: '#7AD7F0' }]);
    const [unScheduledData, setUnScheduledData]: any = useState('');
    const [isShowNoData, setIsShowNoData] = useState(false);
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [startDate, setStartDate] = useState(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    const [isDateChange, setIsDateChange] = useState(false);
    const [colors, setColors] = useState(['#FFCD46', '#4c8BF5', '#ed7d31', '#7AD7F0']);
    const [totalUnscheduledCount, setTotalUnscheduledCount] = useState(0);
    const [months, setMonths] = useState(['0-6 Mo.', '7-12 Mo.', '13-17 Mo.', '18-24 Mo.']);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    let controller: any;
    useEffect(() => {
        if (clinicId) {
            // fetchData();
        controller = new AbortController();
            setTimeout(() => {
                fetchDataNew();
            }, 1000);
        }
        return () => {
            if (controller) {
                controller.abort()
            }
        }
    }, [startDate, endDate, clinicId]);

    const fetchDataNew = () => {
        let date: any = moment().subtract(1, 'day').format('YYYY/MM/DD');
        setIsLoading(true);
        fetchUnscheduledData('unscheduled', clinicId, date,controller)
            .then((success) => {
                setIsLoading(false);
                if (success && success.unscheduled && success.unscheduled.length > 0) {
                    setUnScheduledData(
                            success.unscheduled.map((item: any, index: any) => {
                                    return {
                                        name: item.type, y: Number(item.total), color: colors[index],
                                        month: item.type
                                    }
                            })
                    )
                    setTotalUnscheduledCount(_.sumBy(success.unscheduled, function (o: any) { return Number(o.total); }))
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log('Error while fetching data', error);
            })
    }
    
    const fetchData = () => {
        try {
            setIsLoading(true);
            let date: any = [
                { startDate: moment().subtract(6, 'months').format('YYYY-MM-DD'), endDate: moment().subtract(1, 'day').format('YYYY-MM-DD') },
                { startDate: moment().subtract(12, 'months').format('YYYY-MM-DD'), endDate: moment().subtract(6, 'months').subtract(1, 'day').format('YYYY-MM-DD') },
                { startDate: moment().subtract(17, 'months').format('YYYY-MM-DD'), endDate: moment().subtract(12, 'months').subtract(1, 'day').format('YYYY-MM-DD') },
                { startDate: moment().subtract(24, 'months').format('YYYY-MM-DD'), endDate: moment().subtract(17, 'months').subtract(1, 'day').format('YYYY-MM-DD') },
            ];
            Promise.allSettled([
                // fetchChartData('unscheduled', clinicId, date[0].startDate, date[0].endDate),
                // fetchChartData('unscheduled', clinicId, date[1].startDate, date[1].endDate),
                // fetchChartData('unscheduled', clinicId, date[2].startDate, date[2].endDate),
                // fetchChartData('unscheduled', clinicId, date[3].startDate, date[3].endDate)
            ])
                .then((success) => {
                    setIsLoading(false);
                    console.log('successUnSchedule', success);
                    let findCount = success.map((item: any) => { return item.status === "fulfilled" && Number(item.value.unscheduledCount) > 0 });
                    if (findCount) {
                        setUnScheduledData(
                            success.map((item: any, index: any) => {
                                if (item.status === "fulfilled") {
                                    return {
                                        name: moment(date[index].startDate, 'YYYY-MM-DD').format('MMM DD'), y: Number(item.value.unscheduledCount), color: colors[index],
                                        month: months[index]
                                    }
                                }
                            })
                        )
                        setTotalUnscheduledCount(_.sumBy(success, function (o: any) { return Number(o.value.unscheduledCount); }))
                    }  
                }).catch((error) => {
                    setIsLoading(false);
                    console.log('Error while fetching data', error);
                })
        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
        }

        // fetchChartData('unscheduled',clinicId, startDate, endDate)
        //     .then((success) => {
        //         if (success && Number(success.unscheduledCount) > 0) {
        //             setUnScheduledData([{name: moment(startDate,'YYYY-MM-DD').format('MMM DD'), y: Number(success.unscheduledCount), color: '#FFCD46'}])
        //         } else {
        //             setUnScheduledData([]);
        //         }
        //     })
        //     .catch((error) => {
        //         console.log('error while fetching data - schedule', error);
        //     })
    }

    useEffect(() => {
        if (unScheduledData && unScheduledData.length > 0) {
            let options = {
                animationEnabled: true,
                theme: "light2",
                // title: {
                //     text: 'UnScheduled',
                //     fontSize: 10,
                // },
                legend: {
                    fontFamily: "calibri",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontColor: "#9d9d9d",
                    dockInsidePlotArea: false,
                    verticalAlign: "center",
                    horizontalAlign: "right",
                    itemWidth: 140,
                    itemTextFormatter: function (e: any) {
                        return e.dataPoint.month + " -  " + Math.round(e.dataPoint.y);
                    }
                },
                data: [{
                    cursor: "pointer",
                    explodeOnClick: false,
                    innerRadius: "69%",
                    // legendMarkerType: "square",
                    name: 'UnScheduled',
                    radius: "100%",
                    showInLegend: true,
                    startAngle: 90,
                    type: "doughnut",
                    // indexLabel: "{y}",
                    indexLabelFormatter: function (e: any) {
                        if (e.dataPoint.y) {
                            return e.dataPoint.y;
                        }
                    },
                    indexLabelTextAlign: "left",
                    indexLabelPlacement: "inside",
                    indexLabelFontColor: "black",
                    dataPoints: unScheduledData
                }]
            }
            var charts = new CanvasJS.Chart("unscheduled", options);
            charts.render();
        }
    }, [unScheduledData]);

    const handleDateChange = (sDate: any, eDate: any) => {
        setStartDate(sDate);
        setEndDate(eDate);
        setIsDateChange(false)
    }
    return (
        <div className="col-4 mb-4">
            
            <div className="card">
                        <>
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h6 className="m-0">Unscheduled</h6>
                                <div>
                                    <button type="button" onClick={() => { setIsDateChange(true) }} className="btn p-1"><i className="bi bi-calendar-week"></i></button>
                                    {/* <button type="button" className="btn p-1"><i className="bi bi-info-circle"></i></button> */}
                                </div>
                            </div>
                            <div className="card-body" style={{height:'290px'}}>
                            {
                                isLoading ?
                                    <div className="mt-5 loder_in rounded text-center">
                                        <div className="spinner-border align-items-center" role="status">
                                        </div>
                                        <div className="mt-2">Loading...</div>
                                    </div>
                                    :
                                    <>
                                        <div className="graph-area">
                        
                                            {/* <img src={require("../../../assets/images/chart/Unscheduled.png")} alt='image not found' /> */}
                                            {
                                                unScheduledData.length === 0 &&
                                                <div>No Record found.</div>
                                            }
                                            <div className='w-100 h-100' id="unscheduled"></div>
                                        </div>
                                        <div className="bottom-data d-flex gap-3">
                                            <div className="btms-data">
                                                <span>Total Unscheduled</span>
                                                <div>{totalUnscheduledCount}</div>
                                            </div>
                                        </div>
                                    </>
                            }
                            </div>
                        </>
            </div>
            {
                (isDateChange) &&
                <DateModal
                    show={isDateChange}
                    maxDate={moment().subtract(1,'day').format('YYYY/MM/DD')}
                    handleClose={() => { setIsDateChange(false) }}
                    handleDateChange={(sDate: any, eDate: any) => { handleDateChange(sDate, eDate) }}
                />
            }
        </div>
    )
}