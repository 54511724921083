import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import configs from "../configs/apiConfigs";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getChartData = (data: any,type:any) => {
    const request: CommonApiOptions = {
        url: configs.MESSAGE_CENTER_API +'dashboard/'+ type,
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: { 'Content-Type': 'application/json' },
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const updateConfigureStatus = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'setting/',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};
export const fetchConfigureData = () => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "getsettings/",
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const fetchTotalAppointment = (clinicId:any,startDate:any,endDate:any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "statistics/appointments/"+clinicId+"/?from="+startDate+"&to="+endDate,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const fetchTotalProduction = (clinicId:any,startDate:any,endDate:any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "statistics/production/"+clinicId+"/?from="+startDate+"&to="+endDate,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const fetchPatientSentiment = (clinicId:any,startDate:any,endDate:any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "statistics/sentiment/"+clinicId+"/?from="+startDate+"&to="+endDate,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const fetchChartData = (appointmentType:any,clinicId:any,startDate:any,endDate:any,controller:any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "statistics/"+appointmentType+"/"+clinicId+"/?from="+startDate+"&to="+endDate,
    method: METHOD_TYPE.GET,
    apiOptions: {
      signal:controller.signal,
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const fetchUnscheduledData = (appointmentType:any,clinicId:any,startDate:any,controller:any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "statistics/"+appointmentType+"/"+clinicId+"/?date="+startDate,
    method: METHOD_TYPE.GET,
    apiOptions: {
      signal:controller.signal,
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}