import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { NavLink } from 'react-router-dom';
import { getChart } from '../../components/dashboard/common/Charts';
import { getClinicWiseReview, getDashboardCounts, getDashboardData, getOverdueData } from '../../services/mainDashboard';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { loadingEnd, loadingStart } from '../../store/loadingIndicator/loadingIndicatorSlice';
import { addDashboardData } from '../../store/mainDashboard/mainDashboardSlice';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as _ from 'lodash';
import { values } from 'lodash';
import { fetchConfigureData } from '../../services/dashboard';
export const MainDashboard = () => {
  const userData = useAppSelector((state) => state.user.user);
  const dashboardData = useAppSelector((state) => state.dashboard.dashboardData);
  const dispatch = useAppDispatch();
  const [dashboardCountData, setDashboardCountData]: any = useState('');
  const [month, setMonth]: any = useState(moment());
  const [startDate, setStartDate] = useState(moment().startOf('months').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [mainDashboardConfigure, setMainDashboardConfigure]: any = useState({});
  let controller: any;
  useEffect(() => {
    if (userData && userData.clinics && userData.clinics.length > 0) {
      // fetchDashboardCount();
      controller = new AbortController();
      fetchData();
    }
    return () => {
      if (controller) {
        controller.abort()
      }
    }
  }, [userData, startDate, endDate]);
  
  const fetchData = () => {
    dispatch(loadingStart());
    try {
      let request = {
        clinicidlist: userData.clinicId,
        "startdate": startDate,
        "enddate": endDate
      }
      let overdueDate: any = moment().subtract(1, 'day').format('YYYY/MM/DD');
      Promise.allSettled([
        getDashboardData(request),
        getClinicWiseReview(startDate, endDate),
        fetchConfigureData(),
        getOverdueData(overdueDate,controller)
      ])
        .then((success: any) => {
          console.log('successCheck', success);
          dispatch(loadingEnd());
          let data: any;
          if (success && success[0].status === "fulfilled" && success[0].value && success[0].value.data) {
            data = success[0].value.data;
            if (success && success[1].status === "fulfilled" && success[1].value && success[1].value.sentiment) {
              data = data.map((item: any) => {
                let reviews: any = success[1].value.sentiment;
                let findClinic: any = reviews.find((val: any) => { return item.clinicid === val.id });
                if (findClinic >= 0) {
                  return { ...item, review: findClinic.sentiment }
                } else {
                  return { ...item, review: 0 }
                }
              })
            }
            // Overdue
            if (success && success[3].status === "fulfilled" && success[3].value && success[3].value.overdue) {
              data = data.map((item: any, index: number) => {
                let overdueData: any = success[3].value.overdue;
                let findClinic: any = overdueData.find((val: any) => { return item.clinicid === val.id });
                if (findClinic && findClinic.id) {
                  return { ...item, overdue: findClinic }
                } else {
                  return { ...item, overdue: '' }
                }
              });
            }
          }
          setDashboardCountData(data);
          if (success && success[2].status === "fulfilled" && success[2].value && success[2].value.mainSetting) {
            setMainDashboardConfigure(success[2].value.mainSetting)
          }
        })
        .catch((error) => {
          dispatch(loadingEnd());
          console.log('Error', error);
        })
    } catch (error) {
      dispatch(loadingEnd());
      console.log('Error while fetching data', error);
    }
  }

  // const fetchDashboardCount = () => {
  //   let clinicIds = _.map(userData.clinics, 'id');
  //   let request = {
  //     clinicidlist: clinicIds.toString(),
  //     "startdate": startDate,
  //     "enddate": endDate
  //   }
  //   dispatch(loadingStart());
  //   getDashboardData(request)
  //     .then((success) => {
  //       console.log('successCheck',success);
        
  //       dispatch(loadingEnd());
  //       setDashboardCountData(success.data);
  //       dispatch(addDashboardData(success.data));
  //     })
  //     .catch((error) => {
  //       dispatch(loadingEnd());
  //       console.log('Error while fetch counts data', error);
  //     })
  // }

  const handleChangeMonth = (date: any) => {
    setMonth(date);
    setStartDate(moment(date).startOf('months').format('YYYY-MM-DD'));
    if (moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
      setEndDate(moment().format('YYYY-MM-DD'));
    } else {
      setEndDate(moment(date).endOf('months').format('YYYY-MM-DD'));
    }
  }
  return (
    <>
      <section className="middle-navbar px-30 py-4">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between">

            <div className="d-flex align-items-center">
              <div className="nav-title-txt">Main Dashboard</div>
            </div>
            <div className="stp-action d-flex align-items-center">
              {/* <button type="button" className="btn btn-outline-secondary"><i className="bi bi-date"></i> 06 April, 2022 to 05 May, 2022</button> */}
              <KeyboardDatePicker
                views={['month']}
                disableToolbar={true}
                // className='m-2'
                margin="none"
                // id={"date-picker-dialog-day"}
                // name={"date-picker-dialog-day"}
                label="Month"
                format="MMMM"
                minDate={moment().startOf('year').format('YYYY/MM/DD')}
                maxDate={moment().endOf('year').format('YYYY/MM/DD')}
                value={month}
                onChange={handleChangeMonth}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid p-4">
        {
          (dashboardCountData && dashboardCountData.length > 0) ?
            <div className='table-responsive sc2'>
              <table className="table table-responsive table-bordered th-bg">
                <thead>
                  <tr className="nowrap text-center">
                    <th scope="col">Practices ({dashboardCountData.length})</th>
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.review) &&
                      <th scope="col">Review</th>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.patientSummary) &&
                      <th scope="col" colSpan={2}>Patient Summary</th>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.requested) &&
                      <th scope="col" colSpan={3}>Requested</th>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.waitlisted) &&
                      <th scope="col" colSpan={3}>Waitlisted</th>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.completed) &&
                      <th scope="col" colSpan={3}>Completed</th>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.unscheduled) &&
                      <th scope="col" colSpan={3}>Unscheduled</th>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.dueImmediately) &&
                      <th scope="col" colSpan={3}>Due Immediately</th>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.preAppointed) &&
                      <th scope="col" colSpan={3}>Pre-appointed</th>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.cancelled) &&
                      <th scope="col" colSpan={3}>Cancelled</th>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.newPatient) &&
                      <th scope="col" colSpan={3}>New Patient</th>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.reScheduled) &&
                      <th scope="col" colSpan={3}>Re-Scheduled</th>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.overdue) &&
                      <th scope="col" colSpan={4}>Overdue</th>
                    }
                  </tr>

                  <tr className='nowrap bg-alicblue'>
                    <td></td>
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.review) &&
                      <th></th>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.patientSummary) &&
                      <>
                        <th>Total</th>
                        <th>Active</th>
                      </>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.requested) &&
                      <>
                        <th>#</th>
                        <th>%</th>
                        <th>Trend</th>
                      </>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.waitlisted) &&
                      <>
                        <th>#</th>
                        <th>%</th>
                        <th>Trend</th>
                      </>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.completed) &&
                      <>
                        <th>#</th>
                        <th>%</th>
                        <th>Trend</th>
                      </>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.unscheduled) &&
                      <>
                        <th>#</th>
                        <th>%</th>
                        <th>Trend</th>
                      </>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.dueImmediately) &&
                      <>
                        <th>#</th>
                        <th>%</th>
                        <th>Trend</th>
                      </>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.preAppointed) &&
                      <>
                        <th>#</th>
                        <th>%</th>
                        <th>Trend</th>
                      </>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.cancelled) &&
                      <>
                        <th>#</th>
                        <th>%</th>
                        <th>Trend</th>
                      </>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.newPatient) &&
                      <>
                        <th>#</th>
                        <th>%</th>
                        <th>Trend</th>
                      </>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.reScheduled) &&
                      <>
                        <th>#</th>
                        <th>%</th>
                        <th>Trend</th>
                      </>
                    }
                    {
                      (mainDashboardConfigure && mainDashboardConfigure.overdue) &&
                      <>
                        <th>0-6 Mo.</th>
                        <th>7-12 Mo.</th>
                        <th>13-18 Mo.</th>
                        <th>19-24 Mo.</th>
                      </>
                    }
                  </tr>

                </thead>
                <tbody className="border-top-0">
                  {
              
                    dashboardCountData.map((value: any, index: any) => {
                      return (
                        <tr key={value.clinicid}>
                          <td className='nowrap'><NavLink to="/dashboard" state={{ clinicId: value.clinicid }} className="link-primary">{value.clinicname}</NavLink></td>
                          {
                            (mainDashboardConfigure && mainDashboardConfigure.review) &&
                            <td>{value.review} <i className={Number(value.review) >= 4 ? "bi bi-hand-thumbs-up" : "bi bi-hand-thumbs-down"}></i></td>
                          }
                          {
                            (mainDashboardConfigure && mainDashboardConfigure.patientSummary) &&
                            <>
                              <td>{value.totaluser}</td>
                              <td>{value.activeuser}</td>
                            </>
                          }
                          {
                            (mainDashboardConfigure && mainDashboardConfigure.requested) &&
                            <>
                              <td>{value.requestedappointment}</td>
                              <td>{value.requestedappointmentper ? value.requestedappointmentper : '-'}</td>
                              <td><i className={value.requestedappointmenttrend > 0 ? "bi bi-graph-up-arrow text-success" : "bi bi-graph-down-arrow text-danger"}></i></td>
                            </>
                          }
                          {
                            (mainDashboardConfigure && mainDashboardConfigure.waitlisted) &&
                            <>
                              <td>{value.waitlistappointment}</td>
                              <td>{value.waitlistappointmentper ? value.waitlistappointmentper : '-'}</td>
                              <td><i className={value.waitlistappointmenttrend > 0 ? "bi bi-graph-up-arrow text-success" : "bi bi-graph-down-arrow text-danger"}></i></td>
                            </>
                          }
                          {
                            (mainDashboardConfigure && mainDashboardConfigure.completed) &&
                            <>
                              <td>{value.completedappointment}</td>
                              <td>{value.completedappointmentper ? value.completedappointmentper : '-'}</td>
                              <td><i className={value.completedappointmenttrend > 0 ? "bi bi-graph-up-arrow text-success" : "bi bi-graph-down-arrow text-danger"}></i></td>
                            </>
                          }
                          {
                            (mainDashboardConfigure && mainDashboardConfigure.unscheduled) &&
                            <>
                              <td>{value.unscheduledappointment}</td>
                              <td>{value.unscheduledappointmentper  ? value.unscheduledappointmentper : '-'}</td>
                              <td><i className={value.unscheduledappointmenttrend > 0 ? "bi bi-graph-up-arrow text-success" : "bi bi-graph-down-arrow text-danger"}></i></td>
                            </>
                          }
                          {
                            (mainDashboardConfigure && mainDashboardConfigure.dueImmediately) &&
                            <>
                              <td>{value.dueimmediateappointment}</td>
                              <td>{value.dueimmediateappointmentper ? value.dueimmediateappointmentper : '-'}</td>
                              <td><i className={value.dueimmediateappointmenttrend > 0 ? "bi bi-graph-up-arrow text-success" : "bi bi-graph-down-arrow text-danger"}></i></td>
                            </>
                          }
                          {
                            (mainDashboardConfigure && mainDashboardConfigure.preAppointed) &&
                            <>
                              <td>{value.preappointedappointment}</td>
                              <td>{value.preappointedappointmentper ? value.preappointedappointmentper : '-'}</td>
                              <td><i className={value.preappointedappointmenttrend > 0 ? "bi bi-graph-up-arrow text-success" : "bi bi-graph-down-arrow text-danger"}></i></td>
                            </>
                          }
                          {
                            (mainDashboardConfigure && mainDashboardConfigure.cancelled) &&
                            <>
                              <td>{value.cancelledappointment}</td>
                              <td>{value.cancelledappointmentper ? value.cancelledappointmentper : '-'}</td>
                              <td><i className={value.cancelledappointmentrend > 0 ? "bi bi-graph-up-arrow text-success" : "bi bi-graph-down-arrow text-danger"}></i></td>
                            </>
                          }
                          {
                            (mainDashboardConfigure && mainDashboardConfigure.newPatient) &&
                            <>
                              <td>{value.newpatient}</td>
                              <td>{value.newpatientper ? value.newpatientper : '-'}</td>
                              <td><i className={value.newpatienttrend > 0 ? "bi bi-graph-up-arrow text-success" : "bi bi-graph-down-arrow text-danger"}></i></td>
                            </>
                          }
                          {
                            (mainDashboardConfigure && mainDashboardConfigure.reScheduled) &&
                            <>
                              <td>{value.rescheduleappointment}</td>
                              <td>{value.rescheduleappointmentper ? value.rescheduleappointmentper : '-'}</td>
                              <td><i className={value.rescheduleappointmenttrend > 0 ? "bi bi-graph-up-arrow text-success" : "bi bi-graph-down-arrow text-danger"}></i></td>
                            </>
                          }
                          {
                            (mainDashboardConfigure && mainDashboardConfigure.overdue) &&
                            <>
                              <td>{value.overdue.zeroToSix && value.overdue.zeroToSix.count ? value.overdue.zeroToSix.count : '-' }</td>
                              <td>{value.overdue.zeroToSix && value.overdue.sevenToTwelve.count ? value.overdue.sevenToTwelve.count :'-'}</td>
                              <td>{value.overdue.zeroToSix && value.overdue.thirteenToEighteen.count ? value.overdue.thirteenToEighteen.count :'-'}</td>
                              <td>{value.overdue.zeroToSix && value.overdue.nineteenToTwentyfour.count ? value.overdue.nineteenToTwentyfour.count :'-'}</td>
                            </>
                          }
                        </tr>
                      )
                    })
                  }
            

                  {/* <tr>
              <td><NavLink to="/dashboard" state={{clinicId:1}} className="link-primary">Pathways  Dental</NavLink></td>
              <td>4.8 <i className="bi bi-hand-thumbs-up"></i></td>
              <td>4322</td>
              <td>2103</td>

                <td>35</td>
                <td>3</td>
                <td><i className="bi bi-graph-up-arrow text-success"></i></td>

                <td>156</td>
                <td>14</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>
                <td>18</td>
                <td>1</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>

                <td>18</td>
                <td>1</td>
                <td><i className="bi bi-graph-up-arrow text-success"></i></td>

                <td>33</td>
                <td>3</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>
              </tr>

              <tr>
                <td><NavLink to="/dashboard" state={{clinicId:3}} className="link-primary">West Vaughan Dental</NavLink></td>
                <td>4.2 <i className="bi bi-hand-thumbs-up"></i></td>
                <td>5412</td>
                <td>1415</td>

                <td>46</td>
                <td>3</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>

                <td>42</td>
                <td>3</td>
                <td><i className="bi bi-graph-up-arrow text-success"></i></td>

                <td>213</td>
                <td>16</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>
                <td>23</td>
                <td>2</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>

                <td>23</td>
                <td>2</td>
                <td><i className="bi bi-graph-up-arrow text-success"></i></td>

                <td>43</td>
                <td>3</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>
              </tr>
              <tr>
                <td><NavLink to="/dashboard" state={{clinicId:4}} className="link-primary">Desired Smiles</NavLink></td>
                <td>4.3 <i className="bi bi-hand-thumbs-up"></i></td>
                <td>3362</td>
                <td>1975</td>

                <td>33</td>
                <td>2</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>

                <td>31</td>
                <td>2</td>
                <td><i className="bi bi-graph-up-arrow text-success"></i></td>

                <td>230</td>
                <td>12</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>
                <td>16</td>
                <td>1</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>

                <td>16</td>
                <td>1</td>
                <td><i className="bi bi-graph-up-arrow text-success"></i></td>

                <td>30</td>
                <td>2</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>
              </tr>

              <tr>
                <td><NavLink to="/dashboard" state={{clinicId:5}} className="link-primary">West Metro Dental</NavLink></td>
                <td>3.9 <i className="bi bi-hand-thumbs-down"></i></td>
                <td>3826</td>
                <td>1529</td>

                <td>39</td>
                <td>3</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>

                <td>36</td>
                <td>3</td>
                <td><i className="bi bi-graph-up-arrow text-success"></i></td>

                <td>187</td>
                <td>14</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>

                <td>18</td>
                <td>2</td>
                <td><i className="bi bi-graph-down-arrow text-danger"></i></td>

                <td>18</td>
                <td>2</td>
                <td><i className="bi bi-graph-up-arrow text-success"></i></td>

            <tr>
              <td><NavLink to="/dashboard" state={{clinicId:5}} className="link-primary">West Metro Dental</NavLink></td>
              <td>3.9 <i className="bi bi-hand-thumbs-down"></i></td>
              <td>3826</td>
              <td>1529</td>

              <td>39</td>
              <td>3</td>
              <td><i className="bi bi-graph-down-arrow text-danger"></i></td>

              <td>36</td>
              <td>3</td>
              <td><i className="bi bi-graph-up-arrow text-success"></i></td>

              <td>187</td>
              <td>14</td>
              <td><i className="bi bi-graph-down-arrow text-danger"></i></td>

              <td>18</td>
              <td>2</td>
              <td><i className="bi bi-graph-down-arrow text-danger"></i></td>

              <td>18</td>
              <td>2</td>
              <td><i className="bi bi-graph-up-arrow text-success"></i></td>

              <td>37</td>
              <td>3</td>
              <td><i className="bi bi-graph-down-arrow text-danger"></i></td>
            </tr> */}
                </tbody>
              </table>
            </div>
            : <div>No Record found.</div>
        }
      </div>
    </>
  )
}