import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/index'

// Define a type for the slice state
interface ChartState {
  chartData: any
}

// Define the initial state using that type
const initialState: ChartState = {
//   chartData: {
//     scheduled: {},
//     newPatient: {},
//     Cancellation: {},
//     reAppointment: {},
//     unscheduled: {},
//     hygieneTreatmentPlan: {},
//     restorativeTreatmentPlan: {},
//     providerProduction: {},
//     clinicProduction: {},
//   },
    chartData:{}
}

export const chartSlice = createSlice({
  name: 'chart',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
      addChartAllData: (state, action: PayloadAction<any>) => {
          let data = action.payload
          state.chartData = {...state.chartData,...data}
      },
  },
})

export const { addChartAllData } = chartSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.user.user

export default chartSlice.reducer