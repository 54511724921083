import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/index'

// Define a type for the slice state
interface UserState {
  configure: any
}

// Define the initial state using that type
const initialState: UserState = {
  configure: {
    appointment: true,
    production: true,
    patientSentimate: true,
    pastDueAr: true,
    unscheduledFamily: true,
    scheduled: true,
    newPatient: true,
    Cancellation: true,
    reAppointment: true,
    unscheduled: true,
    hygieneTreatmentPlan: true,
    restorativeTreatmentPlan: true,
    productionAppointment:true
  },
}

export const dashboardConfigureSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    manageConfigure: (state,action: PayloadAction<number>) => {
      state.configure = action.payload
    },
  },
})

export const { manageConfigure } = dashboardConfigureSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.user.user

export default dashboardConfigureSlice.reducer