import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import { addGoalSetting, getGoalSetting } from '../../services/goals';
import * as _ from 'lodash';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../store/hook';
import { loadingEnd, loadingStart } from '../../store/loadingIndicator/loadingIndicatorSlice';

export const General = () => {
  let data: any = useOutletContext();
  const dispatch = useAppDispatch();
  const [selectedClinics, setSelectedClinics] = useState([]);
  const { register, setError, handleSubmit, setValue, getValues, formState: { errors } } = useForm();
  const [isMultipleClinicSelection, setIsMultipleClinicSelection] = useState(false);
  
  useEffect(() => {
    
    if (data && data.selectedClinics.length > 0) {
      setIsMultipleClinicSelection(data.isMultipleSelections);
      setSelectedClinics(data.selectedClinics);
      if (data.selectedClinics.length === 1 && data.selectedClinics[0].id !== 'all') {
        fetchSettingsData();
      } else {
         setValue('visitgoaldaily', 20);
          setValue('visitgoalweekly', 100);
          setValue('visitgoalmonthly', 400);
          setValue('appointmentrate', 90);
          setValue('cancellationrate', 5);
          setValue('noshowrate', 5);
      }
    }
  }, [useOutletContext()]);

  const fetchSettingsData = () => {
    let clinicIds = _.map(data.selectedClinics, 'id');
    dispatch(loadingStart());
    getGoalSetting({ clinicId: clinicIds.toString() })
      .then((success) => {
        dispatch(loadingEnd());
        if (success && success.goal_configuration) {
          setValue('visitgoaldaily', success.goal_configuration.visitgoaldaily);
          setValue('visitgoalweekly', success.goal_configuration.visitgoalweekly);
          setValue('visitgoalmonthly', success.goal_configuration.visitgoalmonthly);
          setValue('appointmentrate', success.goal_configuration.appointmentrate);
          setValue('cancellationrate', success.goal_configuration.cancellationrate);
          setValue('noshowrate', success.goal_configuration.noshowrate);
        } else {
          setValue('visitgoaldaily', '');
          setValue('visitgoalweekly', '');
          setValue('visitgoalmonthly', '');
          setValue('appointmentrate', '');
          setValue('cancellationrate', '');
          setValue('noshowrate', '');
        }
      })
      .catch((error) => {
        dispatch(loadingEnd());
        console.log('Error while fetching setting data', error);
      
      })
  }

  const updateSetting = (datas: any) => {
    if (!data || data.selectedClinics.length === 0) {
      toast.error('Select clinic');
      return;
    }
    let request = {
      clinic_id: _.map(data.selectedClinics, 'id').toString(),
      type:'goal',
      goal_configuration:datas
    }
    console.log('request',request);
    dispatch(loadingStart());
    addGoalSetting(request)
      .then((success) => {
        dispatch(loadingEnd());
        if (success) {
          data.handleIsMultipleClinicSelection(false);
          fetchSettingsData();
          if (success && success.status && success.status.msg) {
            toast.success(success.status.msg);
          }
        }
      })
      .catch((error) => {
        dispatch(loadingEnd());
        console.log('Eroor while update setting', error);
      })
  }
  
  return (
    <>
      <section className="goalsetup-sec px-3 pt-4">
        <div className="container">
          <div className="gset-list">
            <div className="glist-item border-bottom pb-2 mb-2">
              <div>Visit Goal (Daily)</div>
              <div>is greater than</div>
              <div className="">
                <input type="text" disabled={!isMultipleClinicSelection} className="form-control text-center" {...register('visitgoaldaily',{required:true})} />
              </div>
            </div>
            <div className="glist-item border-bottom pb-2 mb-2">
              <div>Visit Goal (Weekly)</div>
              <div>is greater than</div>
              <div className="d-flex gap-2 align-items-center">
                <input type="text" disabled={!isMultipleClinicSelection} className="form-control text-center"  {...register('visitgoalweekly',{required:true})} />
              </div>
            </div>

            <div className="glist-item border-bottom pb-2 mb-2">
              <div>Visit Goal (Monthly)</div>
              <div>is greater than</div>
              <div className="d-flex gap-2 align-items-center">
                <input type="text" disabled={!isMultipleClinicSelection} className="form-control text-center"  {...register('visitgoalmonthly',{required:true})} />
              </div>
            </div>

            <div className="glist-item border-bottom pb-2 mb-2">
              <div>Re-appointment Rate</div>
              <div>is greater than</div>
              <div className="d-flex gap-2 align-items-center">
                <input type="text" disabled={!isMultipleClinicSelection} className="form-control text-center" {...register('appointmentrate',{required:true})} />
              </div>
              <div>%</div>
            </div>

            <div className="glist-item border-bottom pb-2 mb-2">
              <div>Cancellation Rate</div>
              <div>is lower than</div>
              <div className="d-flex gap-2 align-items-center">
                <input type="text" disabled={!isMultipleClinicSelection} className="form-control text-center" {...register('cancellationrate',{required:true})} />
              </div>
              <div>%</div>
            </div>

            <div className="glist-item border-bottom pb-2 mb-2">
              <div>No-show Rate</div>
              <div>is lower than</div>
              <div className="d-flex gap-2 align-items-center">
                <input type="text" disabled={!isMultipleClinicSelection} className="form-control text-center" {...register('noshowrate',{required:true})} />
              </div>
              <div>%</div>
            </div>
            {
              isMultipleClinicSelection &&
              <div className='d-flex justify-content-end mt-3'>
                <button className='btn btn-primary' onClick={handleSubmit(updateSetting)}>Update</button>
              </div>
            }

          </div>
        </div>
      </section>

    </>
  )
}
