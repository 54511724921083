import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import configs from "../configs/apiConfigs";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getDashboardCounts = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.MESSAGE_CENTER_API + 'dashboard/getdashboardcounts',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: { 'Content-Type': 'application/json' },
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const getDashboardData = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.MESSAGE_CENTER_API + 'analytics/getclinicsdashboardcount',
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: { 'Content-Type': 'application/json' },
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};
export const getClinicWiseReview = (startDate:any,endDate:any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "statistics/allclinicsentiment/?from="+startDate+"&to="+endDate,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const getOverdueData = (date:any,controller?:any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "statistics/alloverdue/?date="+date,
    method: METHOD_TYPE.GET,
    apiOptions: {
      signal:controller.signal,
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}
export const downloadOverdueData = (clinicId:any,date:any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "download/overdue/"+clinicId+"/?date="+date,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}