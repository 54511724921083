import React from 'react'
import { PatientAcceptance } from './PatientAcceptance'
import { PatientDiagnosed } from './PatientDiagnosed'

export const RestorativeTreatment = () => {
    return (
        <div className="col-4 mb-4">
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h6 className="m-0">Restorative Treatment Plan</h6>
                    <div>
                        <button type="button" className="btn p-1"><i className="bi bi-list-task"></i></button>
                        <button type="button" className="btn p-1"><i className="bi bi-info-circle"></i></button>
                    </div>
                </div>
                <div className="card-body" style={{height:'290px'}}>

                    <div className="graph-area">
                        {/* <img src={require("../../../../assets/images/chart/Restorative.png")} alt='image not found' /> */}
                        <div className='d-flex flex-row h-100 w-100'>
                            <PatientDiagnosed />
                            <PatientAcceptance />
                        </div>
                    </div>

                    <div className="bottom-data d-flex gap-3">
                        <div className="btms-data">
                            <span>Patients Diagnosed</span>
                            <div>06</div>
                        </div>

                        <div className="btms-data">
                            <span>Unscheduled Treatment</span>
                            <div>$1,260</div>
                        </div>

                        <div className="btms-data">
                            <span>Patients Accepted</span>
                            <div>02</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
