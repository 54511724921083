import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DateWeekModal from './common/DateWeekModal';
import { CustomDateModal } from './common/CustomDateModal';
import WeekModal from './common/WeekModal';
import MonthModal from './common/MonthModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hook';

export const Filter = ({dateType,handleStartEndDateChange,handleClinicChange}:any) => {
    const [isDateChange, setIsDateChange] = useState(false);
    const [isCustomDateChange, setIsCustomDateChange] = useState(false);
    const [isMonthChange, setIsMonthChange] = useState(false);
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const history = useNavigate();
    const [selectedClinic, setSelectedClinic] = useState('');
    const userData = useAppSelector((state) => state.user.user);
    const location: any = useLocation();
    const [clinicList, setClinicList] = useState([{ id: 0, name: 'Fieldgate Dental' }, { id: 1, name: 'Pathways Dental' },
        { id: 2, name: 'Wellness Clinic' },{id:3,name:'West Vaughan Dental'},{id:4,name:'Desired Smiles'},
    {id:5,name:'West Metro Dental'}])

    useEffect(() => {
        if (userData && userData.clinics && userData.clinics.length > 0) {
            if (location && location.state && location.state.clinicId >= 0) {
                setSelectedClinic(location.state.clinicId);
                handleClinicChange(location.state.clinicId);
            } else {
                setSelectedClinic(userData.clinics[0].id);
                handleClinicChange(userData.clinics[0].id);
            }
        }
    },[userData]);


    const handleDateChange = (sDate: any, eDate: any,type:any) => {
        // setIsDateChange(false);
        setIsCustomDateChange(false);
        setStartDate(sDate);
        setEndDate(eDate);
        handleStartEndDateChange(sDate, eDate, type)
        // setIsMonthChange(false)
    }

    const handleTodayBtn = () => {
        let sDate: any = moment().format('YYYY-MM-DD');
        let eDate: any = moment().format('YYYY-MM-DD');
        setStartDate(sDate);
        setEndDate(eDate);
        handleStartEndDateChange(sDate,eDate,'today')
    }

    const handleClinic = (e:any) => {
        setSelectedClinic(e.target.value);
        handleClinicChange(e.target.value);
    }
    
    return (
        <section className="middle-navbar px-30 py-4">
            <div className="p-0 container-fluid">
                <div className="d-flex justify-content-between">

                    <div className="d-flex align-items-center">
                        <button type="button" className={dateType === 'today' ? "btn btn-primary rounded-pill px-3 py-1 me-2":"btn btn-outline-secondary rounded-pill px-3 py-1 me-2"} onClick={()=>{handleTodayBtn()}}>Today</button>
                        <button type="button" className={dateType === 'week' ? "btn btn-primary rounded-pill px-3 py-1 me-2" : "btn btn-outline-secondary rounded-pill px-3 py-1 me-2"} onClick={() => {
                            handleDateChange(moment().startOf('week').format('YYYY-MM-DD'),moment().endOf('week').format('YYYY-MM-DD'),'week')
                        }}>Week</button>
                        <button type="button" className={dateType === 'month' ? "btn btn-primary rounded-pill px-3 py-1 me-2" : "btn btn-outline-secondary rounded-pill px-3 py-1 me-2"} onClick={() => {
                            handleDateChange(moment().startOf('month').format('YYYY-MM-DD'),moment().endOf('month').format('YYYY-MM-DD'),'month')
                        }}>Month</button>
                        <button type="button" className={dateType === 'custom' ? "btn btn-primary rounded-pill px-3 py-1 me-2":"btn btn-outline-secondary rounded-pill px-3 py-1 me-2"} onClick={()=>{setIsCustomDateChange(true)}}>Custom</button>
                        <div className="custom_date ms-2">
                            {moment(startDate).format('DD MMMM, YYYY')} to {moment(endDate).format('DD MMMM, YYYY')}
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <select className='form-select' value={selectedClinic} onChange={(e:any) => { handleClinic(e) }}>
                            {
                                (userData && userData.clinics && userData.clinics.length > 0) &&
                                userData.clinics.map((clinic:any) => {
                                    return (
                                        <option key={clinic.id} value={clinic.id}>{clinic.name}</option>
                                    )
                                })
                            }
                        </select>
                        {/* <select className='form-select' value={selectedClinic} onChange={(e:any) => { handleClinic(e) }}>
                            {
                                (clinicList && clinicList.length > 0) &&
                                clinicList.map((clinic:any) => {
                                    return (
                                        <option key={clinic.id} value={clinic.id}>{clinic.name}</option>
                                    )
                                })
                            }
                        </select> */}
                    </div>
                    <div className="stp-action d-flex align-items-center">
                        <button type="button" className="btn btn-outline-secondary" onClick={()=>{history('/configure')}}><i className="bi bi-gear"></i></button>
                    </div>
                </div>
            </div>
            {
                (isDateChange) &&
                <WeekModal
                    show={isDateChange}
                    handleClose={() => { setIsDateChange(false) }}
                    handleDateChange={(sDate: any, eDate: any) => { handleDateChange(sDate, eDate,'week') }}
                />
            }
            {
                (isMonthChange) &&
                <MonthModal
                    show={isMonthChange}
                    handleClose={() => { setIsMonthChange(false) }}
                    handleDateChange={(sDate: any, eDate: any) => { handleDateChange(sDate, eDate,'month') }}
                />
            }
            {
                (isCustomDateChange) &&
                <CustomDateModal
                    show={isCustomDateChange}
                    handleClose={() => { setIsCustomDateChange(false) }}
                    handleChangeStartEndDate={(sDate: any, eDate: any) => { handleDateChange(sDate, eDate,'custom') }}
                />
            }
        </section>
    )
}
