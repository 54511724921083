import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { Filter } from './Filter';
import moment from 'moment';
import { getChart } from './common/Charts';
import { loadingEnd, loadingStart } from '../../store/loadingIndicator/loadingIndicatorSlice';
import { addDashboardChartData } from '../../store/mainDashboard/mainDashboardSlice';
import { getGoalSetting } from '../../services/goals';
import { Scheduled } from './charts/Scheduled';
import { NewPatients } from './charts/NewPatients';
import { Cancellations } from './charts/Cancellations';
import { ReAppointment } from './charts/ReAppointment';
import { UnScheduled } from './charts/UnScheduled';
import { HygieneTreatment } from './charts/hygieneTreatment/HygieneTreatment';
import { RestorativeTreatment } from './charts/resorativeTreatment/RestorativeTreatment';
import { Production } from './charts/Production';
import { useLocation } from 'react-router-dom';
import { fetchConfigureData, fetchPatientSentiment, fetchTotalAppointment, fetchTotalProduction } from '../../services/dashboard';
import { ProviderProduction } from './charts/ProviderProduction';
import { Overdue } from './charts/Overdue';

export const Overview = () => {
    const configure = useAppSelector((state) => state.configuration.configure);
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const userData = useAppSelector((state) => state.user.user);
    const dispatch = useAppDispatch();
    const [selectedClinic, setSelectedClinic] = useState('');
    const dashboardChartData = useAppSelector((state) => state.dashboard.dashboardChartData);
    const [goalConfigurationData, setGoalConfigurationData]: any = useState('');
    const [chartData, setChartData]: any = useState({ appointment: {}, marketing: {}, administrative: {}, reminder: {} });
    const [dateType, setDateType] = useState('today');
    const [appointmentPercentage, setAppointmentPercentage] = useState(0);
    const [productionPercentage, setProductionPercentage] = useState(0);
    const [totalAppointments, setTotalAppointments] = useState(0);
    const [totalProduction, setTotalProduction] = useState(0);
    const [generalConfigure, setGeneralConfigure]:any = useState({});
    const [dynamicConfigure, setDynamicConfigure]: any = useState({});
    const [patientSentiment, setPatientSentiment] = useState(0);
    const [patientSentimentPer, setPatientSentimentPer] = useState(0);
    const [patientSentimentTitle, setPatientSentimentTitle] = useState('Good');
    const location: any = useLocation();

    const handleDateChange = (sDate: any, eDate: any, type: any) => {
        if (((moment(startDate).format('YYYY/MM/DD') !== moment(sDate).format('YYYY/MM/DD')) || (moment(endDate).format('YYYY/MM/DD') !== moment(eDate).format('YYYY/MM/DD'))) && selectedClinic) {
            firstRowData(selectedClinic, sDate,eDate);
            // fetchSettingGoalData(selectedClinic);
            setStartDate(sDate);
            setEndDate(eDate);
            setDateType(type);
        }
        
    }
    const handleClinicChange = (clinic: any) => {
        if (startDate && endDate && selectedClinic !== clinic) {
            fetchSettingGoalData(clinic);
            firstRowData(clinic, startDate, endDate);
            setSelectedClinic(clinic);
        }
    }

    useEffect(() => {
        // fetchChartData();
        fetchSettingsData();
        // if (location && location.state && location.state.clinicId) {
        //     setSelectedClinic(location.state.clinicId);
        // }
        
    }, []);

    const fetchSettingsData = () => {
        dispatch(loadingStart());
        fetchConfigureData()
            .then((success) => {
                dispatch(loadingEnd());
                if (success.generalSetting) {
                    setGeneralConfigure(success.generalSetting);
                }
                if (success.dynamicSetting) {
                    setDynamicConfigure(success.dynamicSetting);
                }
            })
            .catch((error) => {
                dispatch(loadingEnd());
                console.log('Error while fetch configure data', error);
            })
    }

    const firstRowData = (sClinic:any,sDate:any,eDate:any) => {
        try {
            dispatch(loadingStart());
            Promise.allSettled([
                fetchTotalAppointment(sClinic, sDate, eDate),
                fetchTotalProduction(sClinic, sDate, eDate),
                fetchPatientSentiment(sClinic, sDate, eDate)
            ])
                .then((success: any) => {
                    dispatch(loadingEnd());
                    for (let i = 0; i < success.length; i++) {
                        if (success && success[i].status === "fulfilled" && success[i].value.totalAppointments >= 0) {
                            setTotalAppointments(Number(success[i].value.totalAppointments))
                        }
                        else if (success && success[i].status === "fulfilled" && success[i].value.totalProduction >= 0) {
                            setTotalProduction(Number(success[i].value.totalProduction))
                        }
                        else if (success && success[i].status === "fulfilled" && success[i].value >= 0) {
                            let per: any = ((Number(success[i].value.sentiment) / 5) * 100);  
                            setPatientSentiment(Number(success[i].value.sentiment))
                            setPatientSentimentPer(((Number(success[i].value.sentiment) / 5) * 100));
                            if (per < 33) {
                                setPatientSentimentTitle('Poor');
                            } else if (per > 33 && per < 66) {
                                setPatientSentimentTitle('Good')
                            } else {
                                setPatientSentimentTitle('Excellent')
                            }
                        }
                    }
                })
                .catch((error) => {
                    dispatch(loadingEnd());
                    console.log('Error', error);
                })
        } catch (error) {
            dispatch(loadingEnd());
            console.log('Error while fetching data', error);
        }
    }

    useEffect(() => {
        if (totalAppointments) {
            if (goalConfigurationData && goalConfigurationData.goal_configuration) {
                if (dateType === 'today') {
                    setAppointmentPercentage(goalConfigurationData?.goal_configuration?.visitgoaldaily);
                } else if (dateType === 'week') {
                    setAppointmentPercentage(goalConfigurationData?.goal_configuration?.visitgoalweekly);
                } else if (dateType === 'month') {
                    setAppointmentPercentage(goalConfigurationData?.goal_configuration?.visitgoalmonthly);
                } else {
                    setAppointmentPercentage(0);
                }
            }
        }
    }, [totalAppointments]);

    useEffect(() => {
        if (totalProduction) {
            if (dateType === 'today') {
                setProductionPercentage(goalConfigurationData.production_configuration.visitproductiondaily);
            } else if (dateType === 'week') {
                setProductionPercentage(goalConfigurationData.production_configuration.visitproductionweekly);
            } else if (dateType === 'month') {
                setProductionPercentage(goalConfigurationData.production_configuration.visitproductionmonthly);
            } else {
                setProductionPercentage(0);
            }
        }
    }, [totalProduction]);

    useEffect(() => {
        if (selectedClinic && startDate && endDate) {
            // firstRowData();
            // fetchSettingGoalData();
        }
    },[selectedClinic,startDate,endDate]);
    
    const fetchSettingGoalData = (sClinic:any) => {
        dispatch(loadingStart());
        getGoalSetting({ clinicId: sClinic })
            .then((success) => {
                dispatch(loadingEnd());
                if (success && success.goal_configuration) {
                    setGoalConfigurationData(success);
                    if (dateType === 'today') {
                        setAppointmentPercentage(success?.goal_configuration?.visitgoaldaily);
                    } else if (dateType === 'week') {
                        setAppointmentPercentage(success?.goal_configuration?.visitgoalweekly);
                    } else if (dateType === 'month') {
                        setAppointmentPercentage(success?.goal_configuration?.visitgoalmonthly);
                    } else {
                        setAppointmentPercentage(0);
                    }
                }
                if (success && success.production_configuration) {
                    // setGoalConfigurationData(success.production_configuration);
                    if (dateType === 'today') {
                        setProductionPercentage(success.production_configuration.visitproductiondaily);
                    } else if (dateType === 'week') {
                        setProductionPercentage(success.production_configuration.visitproductionweekly);
                    } else if (dateType === 'month') {
                        setProductionPercentage(success.production_configuration.visitproductionmonthly);
                    } else {
                        setProductionPercentage(0);
                    }
                }
            })
            .catch((error) => {
                dispatch(loadingEnd());
                console.log('Error while fetching setting data', error);
            })
    }

    // const fetchChartData = async () => {
    //     try {
    //         dispatch(loadingStart());
    //         const request = {
    //             clinicid: userData.clinics[0].id,
    //             startdate: startDate,
    //             enddate: endDate
    //         }
    //         Promise.allSettled([
    //             // getChart(request, 'getappointmentconfirmationdata'),
    //             // getChart(request, 'getappointmentcanceldata'),
    //             // getChart(request, 'getprescreeningdata'),
    //             // getChart(request, 'getmarketingstatisticdata'),
    //             // getChart(request, 'getreminderstatisticdata'),
    //             // getChart(request, 'getadministrativestatisticdata'),
    //         ]).then((success: any) => {
    //             console.log('success', success);
    //             dispatch(loadingEnd());
    //             let datas: any = {
    //                 appointment: { appointmentConfirmation: success[0].value, appointmentCancellation: success[1].value, appointmentPreScreening: success[2].value },
    //                 marketing: {
    //                     birthday: success[3].value.find((item: any) => item.label === 'Birthday'),
    //                     events: success[3].value.find((item: any) => item.label === "Events"),
    //                     recall: success[3].value.find((item: any) => item.label === "Recall"),
    //                     digitalDoc: success[3].value.find((item: any) => item.label === 'Digital Doc'),
    //                 },
    //                 reminder: {
    //                     scheduled: success[4].value.find((item: any) => item.label === "Scheduled"),
    //                     confirmationPanding: success[4].value.find((item: any) => item.label === "ConfirmationPanding"),
    //                     preScreening: success[4].value.find((item: any) => item.label === "Pre-screening"),
    //                     confirmed: success[4].value.find((item: any) => item.label === "Confirmed")
    //                 },
    //                 administrative: {
    //                     completed: success[5].value.find((item: any) => item.label === "Completed"),
    //                     reserved: success[5].value.find((item: any) => item.label === "Reserved"),
    //                     scheduledReScheduled: success[5].value.find((item: any) => item.label === "Scheduled/Re-scheduled"),
    //                     cancelled: success[5].value.find((item: any) => item.label === "Cancelled"),
    //                     confirmation: success[5].value.find((item: any) => item.label === "Confirmation"),
    //                     preScreening: success[5].value.find((item: any) => item.label === "Pre-screening"),
    //                 }
    //             }
    //             setChartData(datas)
    //             dispatch(addDashboardChartData(datas));
    //         }).catch((error) => {
    //             console.log('errorPromise', error);
                
    //         })
    //     } catch {
    //         dispatch(loadingEnd());
    //         throw Error("Promise failed");
    //     }
    // }
    return (
        <main className="clinisc-dashboard">
            <Filter dateType={dateType} handleStartEndDateChange={handleDateChange} handleClinicChange={handleClinicChange} />
            <div className="container-fluid p-4">
                <div className="chart-section">

                    <div className="fixed-chart">
                        <div className="row">
                            {
                                (generalConfigure && generalConfigure.appointment) &&
                                <div className="col-3">
                                    <div className="card chart-card">
                                        <div className="chartdata d-flex">
                                            <div className="avatar bg-primary flex-shrink-0 avatar-lg img-circle me-3">
                                                <i className="bi bi-calendar2-check"></i>
                                            </div>
                                            <div className="txt-box align-self-center">
                                                <div className="type_title">Appointments</div>
                                                    <div className="fs-3 fw-bold value_num">{totalAppointments}</div>
                                            </div>
                                        </div>
                                            {
                                                (appointmentPercentage > 0 && dateType !== 'custom') &&
                                                <div className="color_line_chart">
                                                    {/* <span className="target" style={{ left: "25%" }}>
                                                <span>9%<br />Top Practice</span>
                                            </span>
                                            <span className="target" style={{ left: "75%" }}>
                                                <span>15%<br />Avg. Practice</span>
                                            </span> */}
                                            
                                                    <svg className="current_count" style={{ left: Math.round(((totalAppointments / appointmentPercentage) * 100)) > 100 ? "100%" : Math.round(((totalAppointments / appointmentPercentage) * 100)) + "%" }} width="32px" viewBox="0 0 31.999 20.999">
                                                        <g transform="translate(-.485 -.002)" data-name="Group 3081">
                                                            <g transform="translate(.484 .001)" fill="#333" data-name="Union 15">
                                                                <path d="m16.534 20.053-2.0643-3.3174-0.14675-0.23584h-0.27777-5.5446c-4.411 0-7.9996-3.5886-7.9996-7.9996 0-4.411 3.5886-7.9996 7.9996-7.9996h14.999c4.411 0 7.9996 3.5886 7.9996 7.9996 0 4.411-3.5886 7.9996-7.9996 7.9996h-4.4772-0.27777l-0.14675 0.23584-2.0643 3.3174z" />
                                                                <path d="m16.534 19.107 1.9333-3.1068h5.0327c4.1353 0 7.4996-3.3643 7.4996-7.4996 0-4.1353-3.3643-7.4996-7.4996-7.4996h-14.999c-4.1353 0-7.4996 3.3643-7.4996 7.4996 0 4.1353 3.3643 7.4996 7.4996 7.4996h6.1001l1.9333 3.1068m0 1.8928-2.4888-3.9996h-5.5446c-4.6944 0-8.4996-3.8052-8.4996-8.4996 0-4.6944 3.8052-8.4996 8.4996-8.4996h14.999c4.6944 0 8.4996 3.8052 8.4996 8.4996 0 4.6944-3.8052 8.4996-8.4996 8.4996h-4.4772l-2.4888 3.9996z" fill="#fff" />
                                                            </g>
                                                            <text transform="translate(5.484 12.001)" fill="#fff" fontFamily="Roboto-Bold, Roboto" fontSize="11" fontWeight="700" stroke="rgba(0,0,0,0)" data-name={Math.round(((totalAppointments / appointmentPercentage) * 100)) > 100 ? 100 : Math.round(((totalAppointments / appointmentPercentage) * 100)) + "%"}>
                                                                <tspan x="0" y="0">{Math.round(((totalAppointments / appointmentPercentage) * 100))}%</tspan>
                                                            </text>
                                                        </g>
                                                    </svg>
                                                </div>
                                            }
                                    </div>
                                </div>
                            }
                            {
                                (generalConfigure && generalConfigure.production) &&
                                <div className="col-3">
                                    <div className="card chart-card">
                                        <div className="chartdata d-flex">
                                            <div className="avatar bg-primary flex-shrink-0 avatar-lg img-circle me-3">
                                                <i className="bi bi-coin"></i>
                                            </div>
                                            <div className="txt-box align-self-center">
                                                <div className="type_title">Production</div>
                                                    <div className="fs-3 fw-bold value_num">${Math.round(totalProduction)}</div>
                                            </div>
                                            </div>
                                            {
                                                (productionPercentage > 0 && dateType !== 'custom') &&
                                                <div className="color_line_chart">
                                                    {/* <span className="target" style={{ left: "25%" }}>
                                                <span>9%<br />Top Practice</span>
                                            </span>
                                            <span className="target" style={{ left: "75%" }}>
                                                <span>15%<br />Avg. Practice</span>
                                            </span> */}
                                            
                                                    <svg className="current_count" style={{ left: Math.round(((totalProduction / productionPercentage) * 100)) > 100 ? '100%' : Math.round(((totalProduction / productionPercentage) * 100)) + "%" }} width="32px" viewBox="0 0 31.999 20.999">
                                                        <g transform="translate(-.485 -.002)" data-name="Group 3081">
                                                            <g transform="translate(.484 .001)" fill="#333" data-name="Union 15">
                                                                <path d="m16.534 20.053-2.0643-3.3174-0.14675-0.23584h-0.27777-5.5446c-4.411 0-7.9996-3.5886-7.9996-7.9996 0-4.411 3.5886-7.9996 7.9996-7.9996h14.999c4.411 0 7.9996 3.5886 7.9996 7.9996 0 4.411-3.5886 7.9996-7.9996 7.9996h-4.4772-0.27777l-0.14675 0.23584-2.0643 3.3174z" />
                                                                <path d="m16.534 19.107 1.9333-3.1068h5.0327c4.1353 0 7.4996-3.3643 7.4996-7.4996 0-4.1353-3.3643-7.4996-7.4996-7.4996h-14.999c-4.1353 0-7.4996 3.3643-7.4996 7.4996 0 4.1353 3.3643 7.4996 7.4996 7.4996h6.1001l1.9333 3.1068m0 1.8928-2.4888-3.9996h-5.5446c-4.6944 0-8.4996-3.8052-8.4996-8.4996 0-4.6944 3.8052-8.4996 8.4996-8.4996h14.999c4.6944 0 8.4996 3.8052 8.4996 8.4996 0 4.6944-3.8052 8.4996-8.4996 8.4996h-4.4772l-2.4888 3.9996z" fill="#fff" />
                                                            </g>
                                                            <text transform="translate(5.484 12.001)" fill="#fff" fontFamily="Roboto-Bold, Roboto" fontSize="11" fontWeight="700" stroke="rgba(0,0,0,0)" data-name={((totalProduction / productionPercentage) * 100) > 100 ? 100 : ((totalProduction / productionPercentage) * 100) + "%"}>
                                                                <tspan x="0" y="0">{Math.round(((totalProduction / productionPercentage) * 100))}%</tspan>
                                                            </text>
                                                        </g>
                                                    </svg>
                                                </div>
                                            }
                                    </div>
                                </div>
                            }
                            {
                                (generalConfigure && generalConfigure.patientSentimate) &&
                                <div className="col-3">
                                    <div className="card chart-card">
                                        <div className="chartdata d-flex">
                                            <div className="avatar bg-primary flex-shrink-0 avatar-lg img-circle me-3">
                                                <i className="bi bi-emoji-smile"></i>
                                            </div>
                                            <div className="txt-box align-self-center">
                                                <div className="type_title">Patient Sentiment</div>
                                                    <div className="fs-3 fw-bold value_num">{Number(patientSentiment) ?Number(patientSentiment):0} <i className="bi bi-star-fill text-warning fs-5"></i></div>
                                            </div>
                                            </div>
                                            {
                                                (dateType !== 'custom') &&

                                                <div className="color_line_chart patient_santi">
                                                    <span className="target" style={{ left: patientSentimentPer+"%",width:'5px' }}>
                                                    </span>
                                                    <span className="unhappy"><i className="bi bi-emoji-frown"></i></span>
                                                    <span className="happy"><i className="bi bi-emoji-heart-eyes"></i></span>
                                                    {/* <span className="senti_status">{patientSentimentTitle}</span> */}
                                                </div>
                                            }
                                    </div>
                                </div>
                            }
                            {
                                (generalConfigure && (generalConfigure.pastDueAr || generalConfigure.unscheduledFamily)) &&
                                <div className="col-3">
                                    <div className="card chart-card">

                                        <div className="row">
                                            {
                                                (generalConfigure && generalConfigure.pastDueAr) &&
                                                <div className="col-6">
                                                    <div className="chartdata d-flex flex-column text-center align-items-center">
                                                        <div className="avatar bg-primary avatar-lg img-circle mb-2">
                                                            <i className="bi bi-hourglass-bottom"></i>
                                                        </div>
                                                        <div className="txt-box align-self-center">
                                                            <div className="type_title">Past due AR</div>
                                                            <div className="fs-3 fw-bold value_num">$21.6k</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (generalConfigure && generalConfigure.unscheduledFamily) &&
                                                <div className="col-6">
                                                    <div className="chartdata d-flex flex-column text-center align-items-center">
                                                        <div className="avatar bg-primary avatar-lg img-circle mb-2">
                                                            <i className="bi bi-hourglass-bottom"></i>
                                                        </div>
                                                        <div className="txt-box align-self-center">
                                                            <div className="type_title">Unscheduled Family</div>
                                                            <div className="fs-3 fw-bold value_num">18</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    <div className="btms-data">
                        <span style={{color:'gray',fontSize:'10px'}}>*all $-production numbers are rounded to nearest integer.</span>
                    </div>

                    <div className="dynamic-chart">
                        <div className="row">
                            {
                                (dynamicConfigure && dynamicConfigure.scheduled) &&
                                <Scheduled clinicId={selectedClinic} />
                            }
                            {
                                (dynamicConfigure && dynamicConfigure.newPatient) &&
                                <NewPatients clinicId={selectedClinic} />
                            }
                            {
                                (dynamicConfigure && dynamicConfigure.Cancellation) &&
                                <Cancellations clinicId={selectedClinic} />
                            }
                            {
                                (dynamicConfigure && dynamicConfigure.reAppointment) &&
                                <ReAppointment clinicId={selectedClinic} />
                            }
                            {
                                (dynamicConfigure && dynamicConfigure.unscheduled) &&
                                <UnScheduled clinicId={selectedClinic} />
                            }
                            {
                                (dynamicConfigure && dynamicConfigure.hygieneTreatmentPlan) &&
                                <HygieneTreatment />
                            }
                            {
                                (dynamicConfigure && dynamicConfigure.restorativeTreatmentPlan) &&
                                <RestorativeTreatment />
                            }
                            {
                                (dynamicConfigure && dynamicConfigure.clinicProductionAppointment) &&
                                <Production clinicId={selectedClinic} />
                            }
                            {
                                (dynamicConfigure && dynamicConfigure.providerProductionAppointment) &&
                                <ProviderProduction clinicId={selectedClinic} />
                            }
                            {
                                (dynamicConfigure && dynamicConfigure.overdue) &&
                                <Overdue clinicId={selectedClinic} />
                            }
                        </div>
                    </div>
                </div>
            </div>



            {/* <div className="row temp-chart adjstimg">
          <div className="col-12 d-flex flex-wrap">
            <AppointmentConfirmation />
          </div>
        </div> */}

        </main>
    )
}