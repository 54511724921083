import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import configs from "../configs/apiConfigs";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const dashboardLogin = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "login/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(false),
            withCredentials: true
        }
    }
    return commonApi(request);
}