import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../../../assets/js/canvasjs.react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { chartDataConfigure, chartOptions, CHART_LABEL, COLORS, getChart } from '../common/Charts';
import moment from 'moment';
import { NoDataPresent } from '../common/NoDataPresent';
import DateWeekModal from '../common/DateWeekModal';
import { FilterIcons } from '../common/FilterIcons';
import { CustomDateModal } from '../common/CustomDateModal';

var CanvasJS:any = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const ReminderPrescreening = ({ clinicId }: any) => {
    const dispatch = useDispatch();
    const [data, setData]: any = useState([]);
    const [chartData, setChartData]: any = useState([]);
    const [isDateChange, setIsDateChange] = useState(false);
    const [isCustomDateChange, setIsCustomDateChange] = useState(false);
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'));
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        if (clinicId) {
            fetchChart();
        }
    }, [startDate, endDate,clinicId]);

    const fetchChart = async () => {
        // dispatch(fetchLoadingPending());
        setIsLoading(true);
        const request = {
            clinicid: clinicId,
            startdate: startDate,
            enddate: endDate
        }
        setData(await getChart(request, 'getreminderstatisticdata'));
        // dispatch(fetchLoadingSuccess(false));
        setIsLoading(false);
    }
    useEffect(() => {
        if (data) {
            let find = data.find((item: any) => item.label === 'Pre-screening');
            if (find) {
                let checkData = find.value.find((item: any) => item.value > 0);
                if (checkData) {
                    setChartData(find.value.reverse());
                } else {
                    setChartData([]);
                }
            } else {
                setChartData([]);
            }
        }
    },[data]);
    useEffect(() => {
        if (chartData && chartData.length > 0) {
            let dataP = chartDataConfigure(chartData);
            let chartOpt = chartOptions(dataP,'Reminder-Pre-screening');
            var charts = new CanvasJS.Chart("reminderprescreening", { ...chartOpt });
            charts.render();
        }
    }, [chartData]);

    const handleDateChange = (sDate: any, eDate: any) => {
        setIsDateChange(false);
        setIsCustomDateChange(false);
        setStartDate(sDate);
        setEndDate(eDate);
    }
    
    return (
        <div className={chartData && chartData.length <= 0 ? "card alert-warning d-flex justify-content-center align-items-center position-relative" : "card"}>
            {
                (isLoading) ?
                    <div className="container-box">
                        <div className="mt-5 loder_in rounded text-center">
                            <div className="spinner-border align-items-center" role="status">
                            </div>
                            <div className="mt-2">Loading...</div>
                        </div>
                    </div>
                    :
                    <div className="container-box">
                        <FilterIcons handleIsDateChange={() => { setIsDateChange(true) }} handleIsCustomDateChange={() => { setIsCustomDateChange(true) }} />
                        <div className="chart-box">
                            {(chartData && chartData.length <= 0) && <NoDataPresent name={'Reminder-Pre-screening'} />}
                            <div className={chartData && chartData.length <= 0 ? 'd-none w-100 h-100' : 'w-100 h-100'} id="reminderprescreening" ></div>
                        </div>
                    </div>
            }
            {
                (isDateChange) &&
                <DateWeekModal
                    show={isDateChange}
                    handleClose={() => { setIsDateChange(false) }}
                    handleDateChange={(sDate:any,eDate:any)=>{handleDateChange(sDate,eDate)}}
                />
            }
            {
                (isCustomDateChange) &&
                <CustomDateModal
                    show={isCustomDateChange}
                    handleClose={() => { setIsCustomDateChange(false) }}
                    handleChangeStartEndDate={(sDate: any, eDate: any) => { handleDateChange(sDate, eDate) }}
                />
            }
        </div>
    )
};
